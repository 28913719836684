import React, { useEffect, useState } from 'react';
import axios from '../../middleware/axiosConfig';
import './FeedbackPage.css';
import FeedbackForm from '../FeedbackForm/FeedbackForm';
import FeedbackList from '../FeedbackList/FeedbackList';

const FeedbackPage = () => {
    const [feedbacks, setFeedbacks] = useState([]);
    const [message, setMessage] = useState('');

    useEffect(() => {
        fetchFeedbacks();
    }, []);

    const fetchFeedbacks = async () => {
        try {
            const URL = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_FEEDBACK_URL}`;
            const response = await axios.get(URL);
            setFeedbacks(response.data);
        } catch (error) {
            console.error('Error obteniendo feedbacks:', error);
        }
    };

    const handleFeedbackSubmit = async (feedback) => {
        try {
            const URL = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_FEEDBACK_URL}`;

            const response = await axios.post(URL, { feedback });
            if (response.status === 201) {
                setMessage('Gracias :)');
                fetchFeedbacks(); // Actualiza la lista de comentarios
            } else {
                setMessage('Error saving feedback');
            }
        } catch (error) {
            console.error('Error saving feedback:', error);
            setMessage('Error saving feedback');
        }
    };

    return (
        <div>
            <FeedbackForm onFeedbackSubmit={handleFeedbackSubmit} message={message} />
            <FeedbackList feedback={feedbacks} />
        </div>
    );
};

export default FeedbackPage;
