import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('autoXtoken') || '');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const savedAuthState = localStorage.getItem('autoXtoken');

    if (savedAuthState) {
      try {
        const decodedUser = jwtDecode(savedAuthState);
        setIsAuthenticated(true);
        setToken(savedAuthState);
        setUser(decodedUser);
      } catch (error) {
        console.error('Invalid token:', error);
        setIsAuthenticated(false);
        setToken('');
        setUser(null);
      }
    }
  }, []);

  const login = (token) => {
    try {
      const decodedUser = jwtDecode(token);
      setIsAuthenticated(true);
      setToken(token);
      setUser(decodedUser);
      localStorage.setItem('autoXtoken', token);
    } catch (error) {
      console.error('Invalid token:', error);
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    setToken('');
    setUser(null);
    localStorage.removeItem('autoXtoken');
  };

  return (
    <AuthContext.Provider value={{ token, isAuthenticated, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
