import React from 'react';
import { useNavigate } from 'react-router-dom';
import './/BackButton.css';

const Navbar = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="navbar">
      <button onClick={goBack} className="back-button">
        ⬅️ Volver
      </button>
    </div>
  );
};

export default Navbar;
