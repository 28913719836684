import React, { useEffect, useState } from 'react';
import axios from '../../middleware/axiosConfig';
import './Items.css';
import { Link } from 'react-router-dom';
import ImageCarousel from '../../components/ImageCarousel/ImageCarousel'

const Items = () => {
    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const ITEMS_PER_PAGE = 10; // Número de elementos por página

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const URL = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_ITEMS_URL}?page=${currentPage}&limit=${ITEMS_PER_PAGE}`;
                const response = await axios.get(URL);
                setItems(response.data.items);
                setTotalPages(response.data.totalPages);
            } catch (error) {
                console.error('Error fetching items:', error);
            }
        };
        fetchItems();
    }, [currentPage]);

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div className="items-container">
            <div className="items-grid">
                {items.map((item, index) => (
                    <div key={item._id} className="items-card">
                            <ImageCarousel images={item.images} model={item.model} />
                            <div>{item.filename}</div>
                            <Link to={`/item/${item._id}`} key={index} className="items-link">
                                <div className="items-info">
                                    <h2>{item.brand} - {item.model}</h2>
                                    <p>Precio: {item.currency} ${item.price}</p>
                                    <p>Categoría: {item.category}</p>
                                </div>
                            </Link>
                        </div>
                ))}
            </div>
            <div className="pagination">
                <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                    Anterior
                </button>
                <span>Página {currentPage} de {totalPages}</span>
                <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                    Siguiente
                </button>
            </div>
        </div>
    );
};

export default Items;
