import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import './Layout.css';
import BackButton from '../../components/BackButton/BackButton';
import SearchBar from '../../components/SearchBar/SearchBar';

const Layout = ({ children }) => {
  const { isAuthenticated, user, logout } = useContext(AuthContext);

  return (
    <div className="layout-container">
      <header className="layout-header">
        <div className="header-top">
          <BackButton />
          <h1>AutoX Marketplace</h1>
        </div>
        <div className="header-bottom">
          <SearchBar />
          <nav className="navbar">
            <Link to="/home">Inicio</Link>
            <Link to="/categorias">Categorías</Link>
            {isAuthenticated ? (
              <>
                <Link to="/compras">Compras</Link>
                <Link to="/publicar">Publicar</Link>
                <Link to="/comentarios">Comentarios</Link>
                <Link to="/publicaciones">Mis Publicaciones</Link>
                <button onClick={logout} className="logout-button">Logout</button>
              </>
            ) : (
              <Link to="/login">Login</Link>
            )}
          </nav>
          {isAuthenticated && user && (
            <div className="user-welcome">
              <p>Bienvenido, {user.name}!</p>
            </div>
          )}
        </div>
      </header>
      <main className="layout-main">
        {children}
      </main>
      <footer className="layout-footer">
        <p>© 2024 AutoX. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Layout;
