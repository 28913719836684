import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import './LoginForm.css'

const LoginForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const { login } = useContext(AuthContext);

    useEffect(() => {
        // Obtener el parámetro 'email' de la URL si existe
        const params = new URLSearchParams(location.search);
        const emailParam = params.get('email');
        if (emailParam) {
            setEmail(emailParam);
        }
    }, [location]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        const URL = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_LOGIN_URL}`;

        try {
            const response = await axios.post(URL, { email, password });
            const TOKEN = response.data.token
            if (TOKEN){
                localStorage.setItem('autoXtoken', TOKEN);
                login(TOKEN);
                navigate('/home');
            }
        } catch (error) {
            setError('Datos incorrectos :(  ');
        }
    };

    return (
        <div className="form-container">
            <h2>Iniciar Sesión</h2>
            <form onSubmit={handleSubmit} className="login-form">
                <input
                    type="email"
                    id="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <input
                    type="password"
                    id="password"
                    placeholder="Contraseña"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button type="submit">Login</button>
            </form>

            <div className="register-suggestion">
                <p>¿No puedes iniciar sesión? <strong>Debes registrarte.</strong></p>
            </div>
            <div className="register-link-container">
                <Link to="/register" className="btn-register">Registrarse</Link>
            </div>
            {error && <p className="error-message">{error}</p>}
        </div>
    );
};

export default LoginForm;
