import React, { useState } from 'react';
import axios from '../../middleware/axiosConfig';
import useToast from '../../hooks/useToast';
import { useNavigate } from 'react-router-dom';

import './RegisterForm.css';

const RegisterForm = () => {
  const showToast = useToast();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    password: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append('name', formData.name);
    data.append('phone', formData.phone);
    data.append('email', formData.email);
    data.append('password', formData.password);

    try {
      const URL = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_REGISTER_URL}`;
      await axios.post(URL, data, {
        headers: {
          'Content-Type': 'application/json'
        },
      });
      showToast('Registro exitoso', 'success');
      navigate(`/login?email=${formData.email}`);
    } catch (error) {
      showToast('Pruebe otro mail y/o teléfono para el registro.', 'warning');
      if (error.response) {
        // console.log("error.response.status: ", error.response.status);
      } else {
        // console.error('Error de red:', error);
      }
    }
  };

  return (
    <div className="form-container">
    <h2>Registro</h2>
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        name="name"
        placeholder="Nombre"
        value={formData.name}
        onChange={handleChange}
        required
      /><br />
      <input
        type="email"
        name="email"
        placeholder="Email"
        value={formData.email}
        onChange={handleChange}
        required
      /><br />
      <input
        type="text"
        name="phone"
        placeholder="Teléfono"
        value={formData.phone}
        onChange={handleChange}
        required
      /><br />
      <input
        type="password"
        name="password"
        placeholder="Contraseña"
        value={formData.password}
        onChange={handleChange}
        required
      /><br />
      <button type="submit">Registrarse</button>
    </form>
  </div>
);
};

export default RegisterForm;
