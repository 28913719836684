import './FeedbackList.css';

const FeedbackList = ({feedback}) => {

    return (
        <div className="feedback-form-container">
            <h2>Comentarios</h2>
            {feedback.map(feedback => (
                <div key={feedback._id} className="comment">
                    <p>{feedback.feedback}</p>
                    <p className="date">{new Date(feedback.createdAt).toLocaleString()}</p>
                </div>
            ))}
        </div>
    );
}
export default FeedbackList;
