import React, { useState } from 'react';
import axios from '../../middleware/axiosConfig';
import CategorySelect from '../CategorySelect/CategorySelect';
import { useNavigate } from 'react-router-dom';
import useToast from '../../hooks/useToast';
import './ItemForm.css';

const ItemForm = () => {
  const showToast = useToast();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    brand: '',
    model: '',
    description: '',
    price: '',
    category: '',
    currency: 'UYU',
    images: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    if (files.length + formData.images.length > 10) {
      showToast('No puedes seleccionar más de 10 imágenes.', 'warning');
      return;
    }

    setFormData({
      ...formData,
      images: [...formData.images, ...files],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append('brand', formData.brand);
    data.append('model', formData.model);
    data.append('description', formData.description);
    data.append('price', formData.price);
    data.append('category', formData.category);
    data.append('currency', formData.currency);
    
    // Añadir múltiples imágenes
    for (let i = 0; i < formData.images.length; i++) {
      data.append('images', formData.images[i]); // Asegúrate de usar 'images'
    }
    try {
      const URL = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_ITEMS_URL}`;
      const response = await axios.post(URL, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      });

      if (response.status === 201) {
        const itemId = response.data.id;
        showToast('Artículo publicado!', 'success');
        navigate(`/item/${itemId}`);
      } else if (response.status === 400 ){
        showToast('Hubo un problema con la publicación. Por favor intenta nuevamente', 'info');
      }
    } catch (error) {
      console.error('Bardo con la publicación:', error);
      showToast('Bardo con la publicación', 'warning');
    }
  };

  return (
    <div className="form-container">
      <h2>Publica un nuevo item</h2>
      <form onSubmit={handleSubmit} className="item-form">
        <input
          type="text"
          name="brand"
          placeholder="Marca"
          value={formData.brand}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="model"
          placeholder="Modelo"
          value={formData.model}
          onChange={handleChange}
          required
        />
        <textarea
          name="description"
          placeholder="Descripción"
          value={formData.description}
          onChange={handleChange}
          required
        />
        <div className="price-currency-container">
          <select
            name="currency"
            value={formData.currency}
            onChange={handleChange}
            required
          >
            <option value="" disabled>Seleccione la moneda</option>
            <option value="USD">USD - Dólares</option>
            <option value="UYU">UYU - Pesos Uruguayos</option>
            <option value="EUR">EUR - Euros</option>
          </select>
          <input
            type="number"
            name="price"
            placeholder="Precio"
            value={formData.price}
            onChange={handleChange}
            required
          />
        </div>
        <CategorySelect value={formData.category} onChange={handleChange} />
        <input
          type="file"
          name="images"
          accept="image/*"
          onChange={handleFileChange}
          multiple
          required
        />
        <div className="image-preview">
          {formData.images.map((image, index) => (
            <img
              key={index}
              src={URL.createObjectURL(image)}
              alt={`Preview ${index}`}
              className="image-thumbnail"
            />
          ))}
        </div>
        <button type="submit">Publicar</button>
      </form>
    </div>
  );
};

export default ItemForm;
