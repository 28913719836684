import React, { useState, useEffect } from 'react';
import axios from '../../middleware/axiosConfig';
import { Link } from 'react-router-dom';
import { capitalizeFirstLetter } from '../utils';

import './Categories.css';

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const URL = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_CATEGORIES_URL}`;
        const response = await axios.get(URL);
        
        if (response.statusText !== 'OK' ) {
          throw new Error('Error al obtener las categorías. ;(');
        }
        setCategories(response.data);

      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="categories-container">
      <div className="categories-grid">
        {categories.map((category, index) => (
          <Link to={`/categoria/${category.name.toLowerCase()}`} key={index} className="categories-link">
            <div className="categories-card">
              <img 
                src={`/assets/categories_icons/${category.name.toLowerCase()}.webp`} 
                alt={`${category.name}`} 
                className="category-image"
              />
              <h1>{capitalizeFirstLetter(category.description)}</h1>
              {/* <h3>{category.description}</h3> */}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Categories;
