import React from 'react'
import FeedbackPage from '../../components/FeedbackPage/FeedbackPage'
import './Feedback.css'

export default function Feedback() {
  return (
  <>
    <div className="feedback-container">
      <FeedbackPage />
    </div>
  </>
  )
}
