import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Home from './pages/Home/Home';
import Layout from './pages/Layout/Layout';
import Category from './pages/Category/Category';
import Categories from './pages/Categories/Categories';
import Myitems from './pages/MyItems/Myitems';
import ItemDetails from './pages/ItemDetails/ItemDetails';
import LoginForm from './pages/LoginForm/LoginForm';
import RegisterForm from './pages/RegisterForm/RegisterForm';
import ProtectedRoute from './contexts/ProtectedRoute';
import PublishItem from './pages/PublishItem/PublishItem';
import Feedback from './pages/Feedback/Feedback';
import Purchase from './pages/Purchase/Purchase';
import ErrorPage from './pages/ErrorPage/ErrorPage'; // Importar la nueva página de error
import { AuthProvider } from './contexts/AuthContext';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import toastConfig from './config/toastConfig';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/login" element={<LoginForm />} />
            <Route path="/home" element={<Home />} />
            <Route path="/" element={<Home />} />
            <Route path="/register" element={<RegisterForm />} />
            <Route path="/categorias" element={<Categories />} />
            <Route path="/categoria/:categoryName" element={<Category />} />
            <Route path="/item/:itemId" element={<ItemDetails />} />

            <Route element={<ProtectedRoute />}>
              <Route path="/compras" element={<Purchase />} />
              <Route path="/comentarios" element={<Feedback />} />
              <Route path="/publicaciones" element={<Myitems />} />
              <Route path="/publicar" element={<PublishItem />} />
            </Route>
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Layout>
        <ToastContainer {...toastConfig} />
      </Router>
    </AuthProvider>
  );
};

export default App;
