import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../middleware/axiosConfig';
import './SearchBar.css';

const SearchBar = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const s3BucketUrl = process.env.REACT_APP_AWS_BUCKET_ITEMS_URL;

  useEffect(() => {
    const fetchResults = async () => {
      if (query.length > 1) {
        try {
          const URL = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_SEARCH }?q=${query}`;
          const response = await axios.get(URL);
          setResults(response.data);
          setShowDropdown(true);
        } catch (error) {
          console.error('Error obteniendo los resultados de busquedas:', error);
        }
      } else {
        setShowDropdown(false);
        setResults([]);
      }
    };

    fetchResults();
  }, [query]);

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const handleResultClick = (result) => {
    // Implementar la lógica de navegación a la página del item seleccionado
    // console.log('Result clicked:', result);
  };

  const handleClose = () => {
    setQuery('');
    setShowDropdown(false);
  };

  return (
    <div className="search-bar-container">
      <input
        type="text"
        className="search-input"
        placeholder="Buscar repuestos..."
        value={query}
        onChange={handleChange}
      />
      {showDropdown && (
        <button className="close-button" onClick={handleClose}>X</button>
      )}
      {showDropdown && (
        <div className="search-dropdown">
        {results.map((result, index) => (
          <Link to={`/item/${result._id}`} key={index} className="categories-link">
           <div
              key={result._id}
              className="search-result"
              onClick={() => handleResultClick(result)}
            >
              <img src={`${s3BucketUrl}${result.image}`} alt={result.brand} className="search-result-image"/>
              <div className="search-result-details">
                <p className="search-result-category">Categoría: {result.category}</p>
                <p className="search-result-brand">Marca: {result.brand}</p>
                <p className="search-result-model">Modelo: {result.model}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
      )}
    </div>
  );
};

export default SearchBar;
