import axios from 'axios';

//TODO
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
});

// Configurar un interceptor de solicitud
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('autoXtoken');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
