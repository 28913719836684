import React, { useEffect, useState } from 'react';
import axios from '../../middleware/axiosConfig';
import './PurchaseList.css';
import { Link } from 'react-router-dom';

const PurchaseList = () => {
    const [purchases, setPurchases] = useState([]);
    const s3BucketUrl = process.env.REACT_APP_AWS_BUCKET_ITEMS_URL;

    useEffect(() => {
        const fetchPurchases = async () => {
            try {
                const URL = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_PURCHASE}`;
                const response = await axios.get(URL);
                setPurchases(response.data);
            } catch (error) {
                console.error('Error obteniendo las compras:', error);
            }
        };
        fetchPurchases();
    }, []);

    return (
        <>
            <div className="purchase-list-container">
                <h2>Mis compras</h2>
                {purchases.map((purchase,index) => (
                    <Link to={`/item/${purchase.item._id}`} key={index} className="categories-link">
                        <div key={purchase._id} className="purchase-card">
                            <p><strong>Marca</strong> {purchase.item.brand}</p>
                            <p><strong>Modelo</strong> {purchase.item.model}</p>
                            <p><strong>Vendedor</strong> {purchase.seller.name}</p>
                            <p><strong>Email</strong> {purchase.seller.email}</p>
                            <p><strong>Teléfono</strong> {purchase.seller.phone}</p>
                            <img src={`${s3BucketUrl}${purchase.item.image}`} alt={purchase.item.model} className="myItems-image"/>
                            <p className="date"><strong>Fecha:</strong> {new Date(purchase.createdAt).toLocaleString()}</p>
                        </div>
                    </Link>
                ))}
            </div>
        </>
    );
};

export default PurchaseList;
