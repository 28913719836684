import React from 'react';
import styles from './styles.module.css';

const ModalBox = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h2>Confirmación de Compra</h2>
        <p>¿Está seguro de que desea realizar esta compra?</p>
        <button className={styles.modalButton} onClick={onConfirm}>Confirmar</button>
        <button className={styles.modalButton} onClick={onClose}>Cancelar</button>
      </div>
    </div>
  );
};

export default ModalBox;
