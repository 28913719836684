import React, { useEffect, useState } from 'react';
import axios from '../../middleware/axiosConfig';
import { Link } from 'react-router-dom';

import './Myitems.css';

const Myitems = () => {
    const [items, setItems] = useState([]);
    const s3BucketUrl = process.env.REACT_APP_AWS_BUCKET_ITEMS_URL;

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const URL = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_MY_ITEMS_URL}`;
                const response = await axios.get(URL);
                setItems(response.data);
            } catch (error) {
                console.error('Error fetching my items:', error);
            }
        };
        fetchItems();
    }, []);

    return (
        <div className="myitems-list-container">
            <h2>Mis publicaciones</h2>
            {items.map((item, index) => (
                <Link to={`/item/${item._id}`} key={index} className="categories-link">
                    <div key={item._id} className="myitems-card">
                        <p><strong>Marca</strong> {item.brand}</p>
                        <p><strong>Modelo</strong> {item.model}</p>
                        <p><strong>Precio</strong> {item.price}</p>
                        <p><strong>Categoría</strong> {item.category}</p>
                        <img src={`${s3BucketUrl}${item.image}`} alt={item.model} className="myItems-image"/>
                        <p className="date"><strong>Fecha:</strong> {new Date(item.createdAt).toLocaleString()}</p>
                    </div>
                </Link>
            ))}
        </div>
    );
};

export default Myitems;
