import React, { useState } from 'react';
import './ImageCarousel.css';

const ImageCarousel = ({ images, model }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const s3BucketUrl = process.env.REACT_APP_AWS_BUCKET_ITEMS_URL;
  console.log(`${s3BucketUrl}${images[currentImageIndex]}`);
  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="carousel-container">
      <button onClick={handlePreviousImage} className="carousel-control prev">‹</button>
      <img
        src={`${s3BucketUrl}${images[currentImageIndex]}`}
        alt={model}
        className="carousel-image"
      />
      <button onClick={handleNextImage} className="carousel-control next">›</button>
    </div>
  );
};


export default ImageCarousel;