import React from 'react'
import PurchaseList from '../../components/PurchaseList/PurchaseList'

export default function Purchase() {
  return (
    <>
      <PurchaseList />
    </>
  )
}
