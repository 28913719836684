import React from 'react';
import Items from '../Items/Items';
import Categories from '../Categories/Categories';
import './Home.css'

const Home = () => {

  return (
    <>
      <div className="landing-page">
        <h1 className="landing-title">Bienvenido a AutoX Marketplace</h1>
        <p className="landing-subtitle">Encuentra los mejores insumos automotores en Uruguay.</p>
        <div className="categories">
          <Categories />
        </div>
        <p className="">Artículos destacados.</p>
        <div className="categories">
          <Items />
        </div>
      </div>
    </>
  );
}

export default Home;