import 'react-toastify/dist/ReactToastify.css';
import {React, useState, useContext} from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from '../../middleware/axiosConfig';
import ModalBox from '../ModalBox/ModalBox';
import { toast } from 'react-toastify';
import './PurchaseButton.css'

const PurchaseButton = ({ itemId, sellerId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const isOwner = user && user.userId === sellerId;
  const navigate = useNavigate();
  const handlePurchase = async () => {
    try {
      const URL = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_PURCHASE}`;
      const response = await axios.post(URL, { itemId });
      if (response.status === 201) {
        setIsModalOpen(false)
        toast.success('Compra exitosa!');
        navigate(`/item/${itemId}`);
      } else {
        toast.error('Problemas para realizar la compra');
      }
    } catch (error) {
      console.log("Error: ", error)
      console.error('Problemas para realizar la compra:', error);
      toast.error('Problemas para realizar la compra');
    }
  };
  return (
    <div>
      {isOwner ? (
      <>
      <p style={styles.message}>No puedes comprar tu propio item</p>
      </>
    ) : (
      <>
      <button 
        onClick={() => setIsModalOpen(true)} 
        disabled={isOwner}
        style={isOwner ? styles.disabledButton : styles.button}
      >Comprar
      </button>
      </>
    )}
      <ModalBox
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handlePurchase}
      />
    </div>
  );
};

export default PurchaseButton;

const styles = {
    container: {
      border: '1px solid #ccc',
      padding: '16px',
      borderRadius: '8px',
      textAlign: 'center',
      maxWidth: '300px',
      margin: 'auto'
    },
    title: {
      fontSize: '1.5em',
      margin: '0 0 16px'
    },
    price: {
      fontSize: '1.2em',
      color: '#333',
      margin: '0 0 16px'
    },
    hiddenPrice: {
      fontSize: '1.2em',
      color: '#888',
      margin: '0 0 16px'
    },
    button: {
      padding: '10px 20px',
      fontSize: '1em',
      color: '#fff',
      backgroundColor: '#28a745',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer'
    },
    disabledButton: {
      padding: '10px 20px',
      fontSize: '1em',
      color: '#fff',
      backgroundColor: '#ccc',
      border: 'none',
      borderRadius: '5px',
      cursor: 'not-allowed'
    }
  };