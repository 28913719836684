import React, { useEffect, useState, useContext } from 'react';
import axios from '../../middleware/axiosConfig';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import PurchaseButton from '../../components/PurchaseButton/PurchaseButton';
import LoginRegister from '../../components/LoginRegister/LoginRegister';
import ImageCarousel from '../../components/ImageCarousel/ImageCarousel'
import './ItemDetails.css';

const ItemDetails = () => {
  const { itemId } = useParams();
  const [item, setItem] = useState(null);
  const { isAuthenticated } = useContext(AuthContext);
  const s3BucketUrl = process.env.REACT_APP_AWS_BUCKET_ITEMS_URL;

  useEffect(() => {
    const fetchItemDetails = async () => {
      try {
        const URL = `${process.env.REACT_APP_BACKEND_URL}/api/item/${itemId}`;
        const response = await axios.get(URL);
        
        if (response.statusText !== 'OK' ) {
          throw new Error('Error');
        }
        setItem(response.data);
      } catch (error) {
        console.error('Error obteniendo los detalles del item:', error);
      }
    };

    fetchItemDetails();
  }, [itemId,s3BucketUrl]);

  if (!item) {
    return <p>Cargando...</p>;
  }

  return (
    <div className="itemDetails-container">
      <div className="item-cardd">
        {/* <img src={`${s3BucketUrl}${item.image}`} alt={item.model} className="itemDetails-image"/> */}
        <ImageCarousel images={item.images} model={item.model} />
        <h2>{item.brand} - {item.model}</h2>
            <p>Precio: ${item.currency} {item.price}</p>
            <p>Categoría: {item.category}</p>
            <p>{item.description}</p>
            {isAuthenticated ? (
                <>
              <PurchaseButton itemId={item._id} sellerId={item.createdBy} />
            </>
              ) : (
                <>
                <div className="item-cardd">
                  <p style={styles.hiddenPrice}>Te invitamos a registrarte para operar en la plataforma.</p>
                  <LoginRegister/>
                </div>
                </>
            )}
        </div>
    </div>
  );
};

export default ItemDetails;

const styles = {
  container: {
    border: '1px solid #ccc',
    padding: '16px',
    borderRadius: '8px',
    textAlign: 'center',
    maxWidth: '300px',
    margin: 'auto'
  },
  title: {
    fontSize: '1.5em',
    margin: '0 0 16px'
  },
  price: {
    fontSize: '1.2em',
    color: '#333',
    margin: '0 0 16px'
  },
  hiddenPrice: {
    fontSize: '1.2em',
    color: '#888',
    margin: '0 0 16px'
  },
  button: {
    padding: '10px 20px',
    fontSize: '1em',
    color: '#fff',
    backgroundColor: '#28a745',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer'
  },
  disabledButton: {
    padding: '10px 20px',
    fontSize: '1em',
    color: '#fff',
    backgroundColor: '#ccc',
    border: 'none',
    borderRadius: '5px',
    cursor: 'not-allowed'
  }
};