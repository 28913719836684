import React, { useEffect, useState } from 'react';
import axios from '../../middleware/axiosConfig';

const CategorySelect = ({ value, onChange }) => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const URL = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_CATEGORIES_URL }`;
                const response = await axios.get(URL);
                 setCategories(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        fetchCategories();
    }, []);

    return (
        <div className="category-select">
            <select className="category-select" value={value} onChange={onChange} name="category" required>
            <option value="">Seleccione una categoría</option>
            {categories.map(category => (
                <option key={category._id} value={category.name}>
                    {category.name}
                </option>
            ))}
        </select>
        </div>
    )
};

export default CategorySelect;
