import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../middleware/axiosConfig';
import { Link } from 'react-router-dom';
import { capitalizeFirstLetter } from '../utils';
import './Category.css';

const Category = () => {
  const { categoryName } = useParams();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const s3BucketUrl = process.env.REACT_APP_AWS_BUCKET_ITEMS_URL;

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const URL = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_ITEMS_FILTER_URL}${categoryName}`;
        const response = await axios.get(URL);

        if (response.statusText !== 'OK' ) {
          throw new Error('Error');
        }
        setItems(response.data.items);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [categoryName]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="category-container">
      <h1 className="category-title">{capitalizeFirstLetter(categoryName)}</h1>
      {items.length === 0 ? (
        <>
        <div className="no-items-message">No hay elementos disponibles en esta categoría por el momento.
        <Link className="category-button" to="/publicar">Publicar</Link>
        </div>
        
        <div >                
        </div>
        </>
      ) : (
          <div className="category-grid">
            {items.map((item, index) => (
              <Link to={`/item/${item._id}`} key={index} className="category-link">
                <div key={index} className="category-card">
                  <img src={`${s3BucketUrl}${item.image}`} alt={item.model} className="category-image"/>
                  <div className="item-details">
                    <h1 className="item-brand">{item.brand}</h1>
                    <h2 className="item-model">{item.model}</h2>
                    <h3 className="item-description">{item.description}</h3>
                  </div>
                </div>
              </Link>
            ))}
          </div>
      )}
    </div>
  );
};

export default Category;
