import React, { useState} from 'react';
import './FeedbackForm.css'

const FeedbackForm = ({ onFeedbackSubmit, message }) => {
  const [feedback, setFeedback] = useState('');

  const handleSubmit = async (e) => {
      e.preventDefault();
      onFeedbackSubmit(feedback);
      setFeedback('');
  };

  return (
    <div className="feedback-form-container">
        <h1>Agradecemos tus aportes para poder seguir mejorando esta plataforma.</h1>
        <form onSubmit={handleSubmit}>
            <textarea
                name="feedback"
                placeholder="Cualquier cosa que se te venga a la cabeza...."
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                required
            />
            <button type="submit">Enviar</button>
        </form>
        {message && <p>{message}</p>}
    </div>
  );
};

export default FeedbackForm;