import React from 'react'
import ItemForm from '../../components/ItemForm/ItemForm'
import './PublishItem.css'

const PublishItem = () => {
  return (
     <div className="publish-container">
        <div className="publish-grid">
          <ItemForm/>
        </div>
      </div>
  )
}

export default PublishItem;